











































































































import { Nullable } from '@/types'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { toLocalCurrency } from '@/utils/currencyFormatter'
import { namespace } from 'vuex-class'
const PlansStore = namespace('PlansStore')
const PlaceTypesStore = namespace('PlaceTypesStore')
import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import { PlanModel, OfferModel } from '@/core/models/PlanModel'
import { AddPremiumInterface } from './AddPremium.vue'
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import RoomsService from '@/modules/MyRoomsPage/RoomsService'
import SelectPlaceTypes, { PlaceTypeInterface } from './SelectPlaceTypes.vue'
import SelectOffer, { OfferInterface } from './SelectOffer.vue'

import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'

import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

@Component({
  name: 'AddPremiumForm',
  components: {
    BaseIcon,
    BaseButton,
    SelectOffer,
    SelectPlaceTypes,
  },
})
export default class AddPremiumForm extends BreakpointsMixin {
  @Prop({ required: true })
  value!: AddPremiumInterface

  @Watch('value.roomId')
  onRoomIdChanged(id: Nullable<number>): void {
    this.showSelectPlaceTypes = false
    this.premiumList = []
    this.selected = []
    this.showError = false
    if (id) this.getRoomDetails(id)
  }

  @PlansStore.Getter
  public planList!: PlanModel[]

  @PlaceTypesStore.Getter
  public placeTypesList!: PlaceTypesModel[]

  get placeTypes(): PlaceTypesModel[] {
    return this.placeTypesList.filter((item) =>
      (this.roomDetails?.place_types || []).includes(item.id)
    )
  }

  get placeTypeItems(): PlaceTypeInterface[] {
    const excluded = this.premiumList
      .filter((premium) => premium.planId !== this.value.planId)
      .map((premium) => premium.placeTypeId)
    return this.placeTypes.map(({ id, name }) => ({
      id,
      name,
      disabled: !this.planPlaceTypes.includes(id) || excluded.includes(id),
    }))
  }

  get plan(): Nullable<PlanModel> {
    return this.planList.find((plan) => plan.id === this.value.planId) || null
  }

  get planOffers(): OfferModel[] {
    return this.plan?.offers || []
  }

  get planPlaceTypes(): number[] {
    return [
      ...new Set(
        this.planOffers
          .filter((item) => item.price && item.period && item.place_type)
          .map((item) => item.place_type)
      ),
    ]
  }

  get allOffers(): OfferModel[] {
    return ([] as OfferModel[]).concat(
      ...this.planList.map((plan) => plan.offers)
    )
  }

  get totalPrice(): number {
    return this.allOffers
      .filter((offer) =>
        this.premiumList.map((premium) => premium.offerId).includes(offer.id)
      )
      .reduce((sum, offer) => sum + offer.price, 0)
  }

  get isShowPremiumButtons(): boolean {
    return (
      this.planList &&
      Boolean(this.planList.length) &&
      Boolean(this.placeTypeItems.length)
    )
  }

  get isEmptyPlaceTypes(): boolean {
    return (
      this.planList &&
      Boolean(this.planList.length) &&
      !this.placeTypeItems.length
    )
  }

  toLocalCurrency = toLocalCurrency
  showSelectPlaceTypes = false
  showError = false
  errorMessage = ''
  roomDetails: Nullable<RoomModel> = null
  premiumList: OfferInterface[] = []
  selected: number[] = []
  baseUrl = process.env.VUE_APP_URL

  created(): void {
    if (this.value.roomId) this.getRoomDetails(this.value.roomId)
  }

  selectPlan(id: number): void {
    this.showError = false
    this.showSelectPlaceTypes = false
    this.selected = this.premiumList
      .filter((premium) => premium.planId === id)
      .map((premium) => premium.placeTypeId)
    const newValue: AddPremiumInterface = {
      ...this.value,
      planId: id,
    }
    this.$emit('input', newValue)
  }

  addCategory(): void {
    if (!this.value.roomId) {
      this.errorMessage = 'Выберите помещение'
      this.showError = true
      return
    }
    if (!this.value.planId) {
      this.errorMessage = 'Выберите тип премиума'
      this.showError = true
      return
    }
    this.showSelectPlaceTypes = true
  }

  updateSelected(values: number[]): void {
    const added = values.filter((id) => !this.selected.includes(id))
    const removed = this.selected.filter((id) => !values.includes(id))

    this.premiumList = [
      ...this.premiumList.filter(
        (premium) => !removed.includes(premium.placeTypeId)
      ),
      ...added.map((id) => ({
        planId: this.value.planId || 0,
        placeTypeId: id,
        offerId: this.getDefaultOfferId(id),
      })),
    ]

    this.selected = values
  }

  updateOffer(value: OfferInterface): void {
    const changed = this.premiumList.find(
      (premium) =>
        premium.planId === value.planId &&
        premium.placeTypeId === value.placeTypeId
    )
    if (changed) {
      const index = this.premiumList.indexOf(changed)
      this.premiumList.splice(index, 1, {
        planId: value.planId,
        placeTypeId: value.placeTypeId,
        offerId: value.offerId,
      })
    }
  }

  getDefaultOfferId(placeTypeId: number): number {
    const offers = this.planOffers
      .filter(
        (offer) =>
          offer.price && offer.period && offer.place_type === placeTypeId
      )
      .sort((a, b) => (a.sort || 0) - (b.sort || 0) || a.period - b.period)
    return offers.length ? offers[0].id : 0
  }

  removePremium(premium: OfferInterface): void {
    this.showSelectPlaceTypes = false
    if (premium.planId === this.value.planId) {
      this.selected = this.selected.filter((id) => id !== premium.placeTypeId)
    }
    const index = this.premiumList.indexOf(premium)
    if (index !== -1) this.premiumList.splice(index, 1)
  }

  async getRoomDetails(id: number): Promise<void> {
    this.roomDetails = await RoomsService.getRoom(id)
  }

  submit(): void {
    const newValue: AddPremiumInterface = {
      ...this.value,
      offers: this.premiumList.map((premium) => premium.offerId),
    }
    this.$emit('input', newValue)
    this.$emit('submit')
  }
}
