


























import { Component, Prop, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'

export interface PlaceTypeInterface {
  id: number
  name: string
  disabled: boolean
}

@Component({
  name: 'SelectPlaceTypes',
  components: {
    BaseButton,
    BaseCheckbox,
  },
})
export default class SelectPlaceTypes extends Vue {
  @Prop({ required: true })
  items!: PlaceTypeInterface[]

  @Prop({ required: true })
  value!: number[]

  values: number[] = []

  created(): void {
    this.values = [...this.value]
  }

  submit(): void {
    this.$emit('input', [...this.values])
    this.$emit('close')
  }
}
